@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100vh;
  overflow: hidden;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .animate-pause {
    animation-play-state: paused;
  }
}

@layer base {
  html {
    font-family: "Poppins", sans-serif;
    font-size: 12.8px;
  }
}

#nprogress .bar {
  @apply !h-1 !bg-cyan-10;
}

#nprogress .spinner-icon {
  @apply !border-t-cyan-10 !border-l-cyan-10;
}
#nprogress .peg {
  box-shadow: 0 0 10px #182fff, 0 0 20px #182fff;
}

.slate-table > button {
  @apply p-0;
}

.toggle-bg:after {
  @apply absolute top-0.5 left-0.5 h-5 w-5 rounded-full border border-red-10 bg-red-10 shadow-sm transition;
  content: "";
}
input:checked + .toggle-bg:after {
  @apply translate-x-full border-[#182FFF] bg-[#182FFF];
}
input:disabled + .toggle-bg:after {
  @apply border-red-20 bg-red-20;
}
input:disabled:checked + .toggle-bg:after {
  @apply translate-x-full border-[#5f6fff] bg-[#5f6fff];
}

.custom-barcode > svg {
  @apply w-[inherit];
}

/* .inapp li:has(a[aria-current="page"]) { */
/* @apply  pb-[8px]  text-gray-30 ; */
/* } */

button[data-state="open"] svg:last-child:not(:only-child) {
  @apply rotate-180 transition-all;
}
button[data-state="closed"] svg:last-child:not(:only-child) {
  @apply transition-all;
}

/* styles.css */
.HoverCardContent {
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}
.HoverCardContent[data-side="top"] {
  animation-name: slideUp;
}
.HoverCardContent[data-side="bottom"] {
  animation-name: slideDown;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.progressbar {
  height: 0.25rem;
  width: 100%;
  background-color: transparent;
}

.ScrollAreaRoot {
  width: 100%;
  height: 100%;
}

.ScrollAreaViewport {
  width: 100%;
  height: 100%;
}

.ScrollAreaScrollbar {
  width: 4px;
  padding: 5px 2px;
}

.ScrollAreaThumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}
.__floater__open {
  padding: 0 !important;
}

.custom-color-input {
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none; /* Firefox */
  @apply h-7 appearance-none rounded-none border-none bg-transparent p-0 outline-none;
}
.Toastify__toast--success .Toastify__progress-bar--success {
  @apply bg-black;
}
.Toastify__toast--success .Toastify__toast-icon svg {
  @apply fill-black;
}
.Toastify__toast-container--bottom-center > .Toastify__toast--warning {
  @apply min-h-fit w-max bg-red-200;
}
.Toastify__toast-container--bottom-center > .Toastify__toast--warning svg {
  @apply rounded-full border border-white fill-red-10 p-1;
}

/* *{
  outline: 1px solid red
} */
